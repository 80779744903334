import axios from "axios";
import fullscreen from "@/views/ipad/Fullscreen";
function createdLoad(){
    if (localStorage.getItem('info') !== null) {
        // 服务端拿的 token 可以从 vuex、localStorage 等地方取
        axios.defaults.headers['token'] = JSON.parse(localStorage.getItem('info')).token;
    } else {
        axios.defaults.headers['token'] = undefined;
    }
    // document.addEventListener('touchstart',function (event) {
    //     if(event.touches.length>1){
    //         event.preventDefault();
    //     }
    // });
    // var lastTouchEnd=0;
    // document.addEventListener('touchend',function (event) {
    //     var now=(new Date()).getTime();
    //     if(now-lastTouchEnd<=300){
    //         event.preventDefault();
    //     }
    //     lastTouchEnd=now;
    // },false);
    // document.addEventListener('gesturestart', function (event) {
    //     event.preventDefault();
    // });
    // 禁用缩放
    // function addMeta() {
    //     $('head').append('<meta name="viewport" content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no" />');
    // }
    // setTimeout(addMeta, 3000);
// //
//     window.ontouchstart = function(e) { e.preventDefault(); };
// 禁用双指放大
    document.documentElement.addEventListener('touchstart', function (event) {
        if (event.touches.length > 1) {
            event.preventDefault();
        }
    }, {
        passive: false
    });
//
// 禁用双击放大
    var lastTouchEnd = 0;
    document.documentElement.addEventListener('touchend', function (event) {
        var now = Date.now();
        if (now - lastTouchEnd <= 300) {
            event.preventDefault();
        }
        lastTouchEnd = now;
    }, {
        passive: false
    });
}
let full = new fullscreen(()=>{})
export {
    createdLoad,
    full
};
